import React from 'react';
import { NavLink } from 'react-router-dom';

export function CouponNav() {
  return (
    <nav className="nav nav-tabs mb-4">
      <NavLink to="/admin/coupons" className="nav-link" activeClassName="active" exact>
        クーポン一覧
      </NavLink>
      <NavLink to="/admin/coupons/NEW" className="nav-link" activeClassName="active">
        通常クーポン作成
      </NavLink>
      <NavLink to="/admin/issue_coupon" className="nav-link" activeClassName="active">
        一人用クーポン作成
      </NavLink>
      <NavLink to="/admin/coupon_upload" className="nav-link" activeClassName="active">
        クーポン一括アップロード
      </NavLink>
    </nav>
  );
}

export default CouponNav;
