/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useHistory } from 'react-router-dom';
import QRCode from 'react-qr-code';
import dayjs from './dayjs';
import CustomerLink from './CustomerLink';
import useSalesItems from './hooks/useSalesItems';
import useElementItems from './hooks/useElementItems';
import salesChannels from './SalesChannels';
import useSalesComponents from './hooks/useSalesComponent';
import useTags from './hooks/useTags';
import { CouponNav } from './CouponNav';

function Coupon(props: { match }) {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [posting, setPosting] = useState(false);
  const [waitingMessage, setWaitingMessage] = useState<string>();
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');

  const [coupon, setCoupon] = useState<any>();
  const [id, setId] = useState<string>();
  const [shops, setShops] = useState<any>();

  const defaultSocialImage = 'https://tokyomixcurry.com/dynamiclinks/preview_image.jpg';
  const salesItems = useSalesItems();
  const elementItems = useElementItems();
  const salesComponents = useSalesComponents();
  const tags = useTags();
  const watchSocialImage = watch('social_image');

  const [qrCodeSize, setQrCodeSize] = useState(128);
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const [qrCopyMessage, setQrCopyMessage] = useState('');
  const [qrCopyStatus, setQrCopyStatus] = useState<'success' | 'error' | ''>('');

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('shops')
      .orderBy('order')
      .onSnapshot((snap) => {
        console.log('onSnapshot Shops');
        const records = {};
        snap.forEach((docSnapshot) => {
          records[docSnapshot.id] = docSnapshot.data();
        });

        setShops(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, []);

  useEffect(() => {
    let givenId: string;
    if (props.match.params.id === 'NEW') {
      setCoupon({
        limit_per_customer: 1,
        social_image: defaultSocialImage,
        remind_expiring: true,
      });
      setValue('social_image', defaultSocialImage);

      givenId = firebase.firestore().collection('coupons').doc().id;
    } else {
      givenId = props.match.params.id;
    }

    setId(givenId);
    const unregisterCouponObserver = firebase
      .firestore()
      .collection('coupons')
      .doc(givenId)
      .onSnapshot((doc) => {
        console.log('onSnapshot Coupon');
        if (doc.exists) {
          setValue('social_image', doc.data()!.social_image);
          setCoupon(doc.data());
        }
      });

    return () => {
      unregisterCouponObserver();
    };
  }, [props.match.params.id, setValue]);

  const emptyDiv = () => <div />;

  const handleUpload = (e, fieldName) => {
    e.preventDefault();

    const file = e.target.files[0];

    let fileName;
    if (file.type === 'image/png') {
      fileName = `${new Date().getTime()}.png`;
    } else if (file.type === 'image/jpeg') {
      fileName = `${new Date().getTime()}.jpg`;
    } else {
      throw new Error("shouldn't come here");
    }

    const path = '/coupons';
    const fullPath = `${path}/${fileName}`;
    const uploadTask = firebase.storage().ref(fullPath).put(file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // progress function ...
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        progressMessage(progress);
      },
      (error) => {
        console.log(error);
      },
      async () => {
        if (uploadTask.snapshot.totalBytes > 500000) {
          window.alert('画像サイズは500キロバイト以内にしてください');
        } else {
          const fileUrl = await uploadTask.snapshot.ref.getDownloadURL();
          setValue(fieldName, fileUrl);
        }
      },
    );
  };

  const progressMessage = (progress: number) => {
    if (progress === 100) {
      setPosting(false);
    } else if (progress > 0) {
      setWaitingMessage(`${progress}%`);
      setPosting(true);
    } else {
      setPosting(false);
    }
  };

  const onSubmit = (data) => {
    const options = {
      title: 'クーポン情報の更新を行います',
      message: '変更は直ちに反映されます',
      buttons: [
        {
          label: '更新する',
          onClick: () => {
            submit(data);
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };

    confirmAlert(options);
  };

  const submit = (argData) => {
    setWaitingMessage('クーポン情報更新中');
    setPosting(true);

    const data = { ...argData };

    data.code = data.code.toUpperCase();
    data.start_period = dayjs.tz(data.start_period, 'Asia/Tokyo').toDate();
    data.end_period = dayjs.tz(data.end_period, 'Asia/Tokyo').toDate();
    if (data.limit_per_customer) {
      data.limit_per_customer = parseInt(data.limit_per_customer, 10);
    } else {
      data.limit_per_customer = null;
    }

    if (data.total_discount_amount_limit_per_customer) {
      data.total_discount_amount_limit_per_customer = parseInt(data.total_discount_amount_limit_per_customer, 10);
    } else {
      data.total_discount_amount_limit_per_customer = null;
    }

    if (data.limit_all) {
      data.limit_all = parseInt(data.limit_all, 10);
    } else {
      data.limit_all = null;
    }
    if (data.limit_per_order) {
      data.limit_per_order = parseInt(data.limit_per_order, 10);
    } else {
      data.limit_per_order = null;
    }
    if (data.minimum_price_per_curry) {
      data.minimum_price_per_curry = parseInt(data.minimum_price_per_curry, 10);
    } else {
      data.minimum_price_per_curry = null;
    }
    if (data.maximum_price_per_curry) {
      data.maximum_price_per_curry = parseInt(data.maximum_price_per_curry, 10);
    } else {
      data.maximum_price_per_curry = null;
    }
    if (data.discount_percentage) {
      data.discount_percentage = parseInt(data.discount_percentage, 10);
    } else {
      data.discount_percentage = null;
    }
    if (data.discount_amount) {
      data.discount_amount = parseInt(data.discount_amount, 10);
    } else {
      data.discount_amount = null;
    }
    if (!data.sales_channels || data.sales_channels.length === 0) {
      data.sales_channels = null;
    }
    if (!data.sales_item_ids || data.sales_item_ids.length === 0) {
      data.sales_item_ids = null;
    }
    if (data.max_sales_items) {
      data.max_sales_items = parseInt(data.max_sales_items, 10);
    } else {
      data.max_sales_items = null;
    }
    if (!data.tag_ids || data.tag_ids.length === 0) {
      data.tag_ids = null;
    }

    if (data.limit_per_day_customer) {
      data.limit_per_day_customer = parseInt(data.limit_per_day_customer, 10);
    } else {
      data.limit_per_day_customer = null;
    }
    if (!data.component_id) {
      data.component_id = null;
    }

    data.require_stripe = data.require_stripe === 'true';
    data.utm_medium = 'coupon';

    data.open = true;
    data.enabled = true;

    const doc = firebase.firestore().collection('coupons').doc(id);

    doc
      .set(data, { merge: true })
      .then(() => {
        setMessageSuccess('クーポン更新完了');
        history.push(`/admin/coupons/${doc.id}`);
        setPosting(false);
      })
      .catch((error) => {
        setMessageError(`更新に失敗しました${error}`);
        setPosting(false);
      });
  };

  const copyQRCodeToClipboard = async () => {
    if (!qrCodeRef.current) return;

    try {
      const svg = qrCodeRef.current.querySelector('svg');
      if (!svg) return;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const border = 30;
      canvas.width = qrCodeSize + border;
      canvas.height = qrCodeSize + border;

      const img = new Image();
      const svgData = new XMLSerializer().serializeToString(svg);
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);

      img.onload = async () => {
        if (!ctx) return;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, border / 2, border / 2, qrCodeSize, qrCodeSize);

        canvas.toBlob(async (blob) => {
          if (!blob) return;
          try {
            const clipboardItem = new ClipboardItem({ 'image/png': blob });
            await navigator.clipboard.write([clipboardItem]);
            setQrCopyMessage('✔️');
            setQrCopyStatus('success');
          } catch (err) {
            setQrCopyMessage('QRコードのコピーに失敗しました');
            setQrCopyStatus('error');
            console.error('Failed to copy QR code:', err);
          }
        }, 'image/png');
      };

      img.src = svgUrl;
    } catch (err) {
      setQrCopyMessage('QRコードのコピーに失敗しました');
      setQrCopyStatus('error');
      console.error('Failed to copy QR code:', err);
    }
  };

  return (
    <div className="container-fluid h-100">
      <CouponNav />

      {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}

      {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}

      {coupon && shops && salesItems && elementItems && salesComponents && tags ? (
        <div>
          <form id="coupon-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">&nbsp;</div>

            {coupon.inviter_id ? (
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">紹介</label>
                <div className="col-sm-8">
                  <CustomerLink customerId={coupon.inviter_id} />
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">店舗専用クーポン</label>
              <div className="col-sm-8">
                <select size={10} multiple defaultValue={coupon.shop_ids || []} {...register('shop_ids', {})}>
                  {Object.keys(shops).map((shopId) => (
                    <option key={shopId} value={shopId}>
                      {shops[shopId].short_name}
                    </option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  無指定の場合は今後作成される店舗含めすべての店舗で使用可能です。{' '}
                </small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">コード</label>
              <div className="col-sm-8">
                <input
                  defaultValue={coupon.code}
                  type="text"
                  size={40}
                  {...register('code', { required: true })}
                  placeholder="例: ZZ12345"
                />
                <small className="text-danger">{errors.code && '必須です'}</small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">割引率(%)</label>
              <div className="col-sm-8">
                <input
                  type="number"
                  defaultValue={coupon.discount_percentage}
                  {...register('discount_percentage', {})}
                  placeholder="例: 50"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">割引額</label>
              <div className="col-sm-8">
                <input
                  type="number"
                  defaultValue={coupon.discount_amount}
                  {...register('discount_amount', {})}
                  placeholder="例: 200"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">集計グループ</label>
              <div className="col-sm-8">
                <input
                  defaultValue={coupon.data_group}
                  type="text"
                  size={40}
                  {...register('data_group', {})}
                  placeholder="例: テイクアウト半額"
                />
                <small className="form-text text-muted">TMCデータでのグループ分けに使われます</small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">ダイナミック(共有)リンク</label>
              <div className="col-sm-8">
                <div>{coupon.dynamic_link || '未作成'}</div>
                {coupon.dynamic_link && (
                  <div className="mt-3">
                    <div className="mb-2">
                      <label className="mr-2">QRコードサイズ:</label>
                      <select
                        value={qrCodeSize}
                        onChange={(e) => setQrCodeSize(Number(e.target.value))}
                        className="custom-select custom-select-sm w-auto"
                      >
                        <option value={128}>小 (128px)</option>
                        <option value={200}>中 (200px)</option>
                        <option value={300}>大 (300px)</option>
                      </select>
                    </div>
                    <div
                      ref={qrCodeRef}
                      className="p-3 bg-white d-inline-block"
                      style={{
                        border: '5px solid white',
                        boxShadow: '0 0 0 1px #e0e0e0',
                        borderRadius: '4px',
                      }}
                    >
                      <QRCode
                        value={coupon.dynamic_link}
                        size={qrCodeSize}
                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                      />
                    </div>
                    <div className="mt-2 d-flex align-items-center">
                      <button type="button" className="btn btn-sm btn-primary" onClick={copyQRCodeToClipboard}>
                        QRコードをコピー
                      </button>
                      {qrCopyMessage && (
                        <span className={`ml-3 ${qrCopyStatus === 'success' ? 'text-success' : 'text-danger'}`}>
                          {qrCopyMessage}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">ソーシャル共有タイトル</label>
              <div className="col-sm-8">
                <input
                  defaultValue={coupon.social_title}
                  type="text"
                  size={40}
                  {...register('social_title', { required: true })}
                  placeholder="例: 【2日間限定】100円OFFクーポン！"
                />
                <small className="text-danger">{errors.social_title && '必須です'}</small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">ソーシャル共有本文</label>
              <div className="col-sm-8">
                <textarea
                  defaultValue={coupon.social_description}
                  {...register('social_description', { required: true })}
                  cols={80}
                  rows={4}
                  placeholder="例: 9/24,25の2日間だけ使える100円OFFクーポンです。"
                />
                <small className="text-danger">{errors.social_description && '必須です'}</small>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputImageUrl" className="col-sm-2 col-form-label">
                ソーシャル共有画像
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  id="inputSocialImage"
                  size={60}
                  {...register('social_image', { required: true })}
                  defaultValue={coupon.social_image}
                />
                <br />
                <small className="form-text text-muted">SNS共有時に使用されます</small>
                <small className="text-danger">{errors.social_image && '必須です'}</small>
                <input
                  type="file"
                  name="file"
                  className="file-select"
                  accept="image/jpeg, image/png"
                  onChange={(e) => handleUpload(e, 'social_image')}
                />
                <button
                  type="button"
                  className="btn btn-small btn-light"
                  onClick={(e) => {
                    e.preventDefault();
                    setValue('social_image', defaultSocialImage);
                  }}
                >
                  デフォルト画像に戻す
                </button>
                &nbsp;
                <button
                  type="button"
                  className="btn btn-small btn-light"
                  onClick={(e) => {
                    e.preventDefault();
                    setValue('social_image', '');
                  }}
                >
                  画像を削除
                </button>
                {watchSocialImage ? <img alt={watchSocialImage} src={watchSocialImage} width="400px" /> : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">開始日時</label>
              <div className="col-sm-8">
                <input
                  type="datetime-local"
                  defaultValue={
                    coupon.start_period
                      ? dayjs(coupon.start_period.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DDTHH:mm')
                      : ''
                  }
                  {...register('start_period', { required: true })}
                />
                <small className="text-danger">{errors.start_period && '必須です'}</small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">終了日時</label>
              <div className="col-sm-8">
                <input
                  type="datetime-local"
                  defaultValue={
                    coupon.end_period
                      ? dayjs(coupon.end_period.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DDTHH:mm')
                      : ''
                  }
                  {...register('end_period', { required: true })}
                />
                <small className="text-danger">{errors.end_period && '必須です'}</small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">終了日にSlack通知</label>
              <div className="col-sm-8">
                <input
                  type="checkbox"
                  value="true"
                  defaultChecked={coupon.remind_expiring}
                  {...register('remind_expiring', {})}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">一人あたり利用可能数</label>
              <div className="col-sm-8">
                <input
                  type="number"
                  defaultValue={coupon.limit_per_customer}
                  {...register('limit_per_customer', { required: true })}
                />
                <small className="text-danger">{errors.limit_per_customer && '必須です'}</small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">一人あたり累計割引金額制限</label>
              <div className="col-sm-8">
                <input
                  type="number"
                  defaultValue={coupon.total_discount_amount_limit_per_customer}
                  {...register('total_discount_amount_limit_per_customer', {})}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">全体での利用可能数</label>
              <div className="col-sm-8">
                <input type="number" defaultValue={coupon.limit_all} {...register('limit_all', {})} />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">一回の注文での割引食数</label>
              <div className="col-sm-8">
                <input type="number" defaultValue={coupon.limit_per_order} {...register('limit_per_order', {})} />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">一回の注文での最少食数</label>
              <div className="col-sm-8">
                <input type="number" defaultValue={coupon.minimum_per_order} {...register('minimum_per_order', {})} />
                <small className="form-text text-muted">この食数未満の注文ではクーポン使用不可になります</small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">一食当たりの最低金額制限(税抜き)</label>
              <div className="col-sm-8">
                <input
                  type="number"
                  defaultValue={coupon.minimum_price_per_curry}
                  {...register('minimum_price_per_curry', {})}
                />
                <small className="form-text text-muted">クーポン値引き前の金額に対する制限です</small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">一食当たりの最高金額制限(税抜き)</label>
              <div className="col-sm-8">
                <input
                  type="number"
                  defaultValue={coupon.maximum_price_per_curry}
                  {...register('maximum_price_per_curry', {})}
                />
                <small className="form-text text-muted">クーポン値引き前の金額に対する制限です</small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">初回注文のみで使用可能</label>
              <div className="col-sm-8">
                <input
                  type="checkbox"
                  defaultChecked={coupon.valid_only_first_order}
                  {...register('valid_only_first_order', {})}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">この時間以降のスロットでのみ使用可能なクーポン</label>
              <div className="col-sm-8">
                <input
                  defaultValue={coupon.valid_after}
                  type="text"
                  size={20}
                  placeholder="例: 12:30"
                  {...register('valid_after', { pattern: /^[0-9][0-9]:[0-9][0-9]$/ })}
                />
                <small className="text-danger">{errors.valid_after && 'HH:MM で指定してください'}</small>
                <small className="form-text text-muted" />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">この時間までのスロットでのみ使用可能なクーポン</label>
              <div className="col-sm-8">
                <input
                  defaultValue={coupon.valid_before}
                  type="text"
                  size={20}
                  placeholder="例: 18:00"
                  {...register('valid_before', { pattern: /^[0-9][0-9]:[0-9][0-9]$/ })}
                />
                <small className="text-danger">{errors.valid_before && 'HH:MM で指定してください'}</small>
                <small className="form-text text-muted">
                  スロットの開始時間で判定。 18:00 と入力した場合、 18:00-18:15のスロットでは使用可になる
                </small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">販売チャネル指定</label>
              <div className="col-sm-8">
                <select
                  multiple
                  size={5}
                  defaultValue={coupon.sales_channels || []}
                  {...register('sales_channels', {})}
                >
                  {salesChannels.map((salesChannel) => (
                    <option key={salesChannel.value} value={salesChannel.value}>
                      {salesChannel.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">販売アイテム(トッピング)指定</label>
              <div className="col-sm-8">
                <select
                  multiple
                  size={5}
                  defaultValue={coupon.sales_item_ids || []}
                  {...register('sales_item_ids', {})}
                >
                  {salesItems.map((salesItem) => (
                    <option key={salesItem.data().id} value={salesItem.data().id}>
                      {salesComponents.find((c) => c.id === salesItem.data().sales_component_id)?.data().name}{' '}
                      {salesItem.data().name} (
                      {elementItems.find((e) => e.id === salesItem.data().element_item_id)?.data()?.name})
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">販売アイテム指定時の最大個数</label>
              <div className="col-sm-8">
                <input type="number" defaultValue={coupon.max_sales_items} {...register('max_sales_items', {})} />
              </div>
              <small className="form-text text-muted">
                販売アイテム(トッピング)指定時にクーポンが有効になる個数。無指定の場合は何個でも対象。
              </small>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">タグ指定</label>
              <div className="col-sm-8">
                <select multiple size={5} defaultValue={coupon.tag_ids || []} {...register('tag_ids', {})}>
                  {tags.map((tag) => (
                    <option key={tag.data().id} value={tag.data().id}>
                      {tag.data().name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">決済手段の登録必須</label>
              <div className="col-sm-8">
                <input
                  type="checkbox"
                  value="true"
                  defaultChecked={coupon.require_stripe}
                  {...register('require_stripe', {})}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">初回に使用したユーザーのみ使用可能</label>
              <div className="col-sm-8">
                <input
                  type="checkbox"
                  defaultChecked={coupon.valid_only_one_customer}
                  {...register('valid_only_one_customer', {})}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">メッセージでクーポンが送られたユーザーのみ使用可能</label>
              <div className="col-sm-8">
                <input
                  type="checkbox"
                  defaultChecked={coupon.valid_only_message_recipient}
                  {...register('valid_only_message_recipient', {})}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">1日N回のみ使用可能</label>
              <div className="col-sm-8">
                <input
                  type="number"
                  defaultValue={coupon.limit_per_day_customer}
                  {...register('limit_per_day_customer', {})}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">トッピング専用クーポン</label>
              <div className="col-sm-8">
                <input
                  type="checkbox"
                  defaultChecked={coupon.component_id === 'topping'}
                  value="topping"
                  {...register('component_id', {})}
                />
                <small className="form-text text-muted">トッピングからだけ割引されます</small>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">サブスクリプション用クーポン</label>
              <div className="col-sm-8">
                <input type="checkbox" defaultChecked={coupon.for_subscription} {...register('for_subscription', {})} />
              </div>
            </div>

            <input type="submit" className="btn btn-primary" value="更新" />
          </form>
        </div>
      ) : (
        <div className="row">
          <div className="col text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}

      <WindmillSpinnerOverlay loading={posting} message={waitingMessage} />
    </div>
  );
}

export default Coupon;
