/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import dayjs from './dayjs';
import { CouponNav } from './CouponNav';

function IssueCoupon(props: { callbackCreatedCoupon: Function }) {
  const { register, handleSubmit } = useForm();
  const [posting, setPosting] = useState(false);
  const [createdCouponCode, setCreatedCouponCode] = useState<string>();
  const [error, setError] = useState('');

  const [shops, setShops] = useState<any>();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('shops')
      .orderBy('order')
      .onSnapshot((snap) => {
        console.log('onSnapshot Shops');
        const records = {};
        snap.forEach((docSnapshot) => {
          records[docSnapshot.id] = docSnapshot.data();
        });

        setShops(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, []);

  const onSubmit = (argData) => {
    const data = { ...argData };

    if (data.discount_amount) {
      data.discount_amount = Number.parseInt(data.discount_amount, 10);
    } else {
      delete data.discount_amount;
    }

    if (data.discount_percentage) {
      data.discount_percentage = Number.parseInt(data.discount_percentage, 10);
    } else {
      delete data.discount_percentage;
    }

    if (data.limit_all) {
      data.limit_all = Number.parseInt(data.limit_all, 10);
    } else {
      delete data.limit_all;
    }

    data.end_period = dayjs.tz(data.end_period, 'Asia/Tokyo').unix();

    if ((!data.discount_amount && !data.discount_percentage) || (data.discount_amount && data.discount_percentage)) {
      setError('値引率/値引額のどちらかを入力してください。');
      return;
    }

    const emptyDiv = () => <div />;
    const options = {
      title: 'クーポンの作成を行います',
      buttons: [
        {
          label: '作成する',
          onClick: () => {
            submit(data);
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };

    confirmAlert(options);
  };

  const submit = (data) => {
    setPosting(true);

    const apiEndPoint = `${process.env.REACT_APP_api_server}/coupons/`;
    const auth = firebase.auth();

    auth.currentUser!.getIdToken().then((token) => {
      fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          const responseJson = await response.json();
          if (response.status === 201) {
            if (props.callbackCreatedCoupon) {
              props.callbackCreatedCoupon(responseJson.coupon.code);
            }
            setCreatedCouponCode(responseJson.coupon.code);
          } else {
            setCreatedCouponCode('クーポン作成に失敗しました');
          }
          setPosting(false);
        })
        .catch(() => {
          setCreatedCouponCode('クーポン作成に失敗しました');
          setPosting(false);
        });
    });
  };

  return (
    <div className="container-fluid h-100">
      <CouponNav />

      <div className="card" style={{ width: '60rem' }}>
        {createdCouponCode ? (
          <div className="card-body">
            <h5 className="card-title">クーポン作成完了</h5>
            <div>{createdCouponCode}</div>
          </div>
        ) : shops ? (
          <div className="card-body">
            <h5 className="card-title">
              クーポン作成
              <button type="button" className="btn btn-sm btn-ligth" onClick={() => setOpen(!open)}>
                {open ? '閉じる' : '開く'}
              </button>
            </h5>
            <form id="coupon-form" onSubmit={handleSubmit(onSubmit)} className={open ? 'd-block' : 'd-none'}>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">値引額</label>
                <div className="col-sm-8">
                  <input type="number" {...register('discount_amount', {})} />
                </div>
                <label className="col-sm-4 col-form-label">値引率</label>
                <div className="col-sm-8">
                  <input type="number" min="0" max="100" {...register('discount_percentage', {})} />%
                </div>

                <label className="col-sm-4 col-form-label">有効期限</label>
                <div className="col-sm-8">
                  <input
                    type="datetime-local"
                    defaultValue={dayjs()
                      .tz('Asia/Tokyo')
                      .add(1, 'month')
                      .add(1, 'day')
                      .endOf('day')
                      .format('YYYY-MM-DDTHH:mm')}
                    {...register('end_period', { required: true })}
                  />
                </div>
                <label className="col-sm-4 col-form-label">使用可能回数</label>
                <div className="col-sm-8">
                  <input type="number" {...register('limit_all', {})} defaultValue={1} />
                </div>

                <label className="col-sm-4 col-form-label">クーポンプレフィックス</label>
                <div className="col-sm-8">
                  <input type="text" defaultValue="CS" size={5} {...register('prefix', { pattern: /^[A-Za-z]*$/ })} />
                </div>

                <label className="col-sm-4 col-form-label">この時間以降のスロットでのみ使用可</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    size={20}
                    placeholder="例: 12:30"
                    {...register('valid_after', { pattern: /^[0-9][0-9]:[0-9][0-9]$/ })}
                  />
                </div>

                <label className="col-sm-4 col-form-label">店舗専用クーポン</label>
                <div className="col-sm-8">
                  <select size={10} multiple {...register('shop_ids', {})}>
                    {Object.keys(shops).map((shopId) => (
                      <option key={shopId} value={shopId}>
                        {shops[shopId].short_name}
                      </option>
                    ))}
                  </select>
                </div>

                <label className="col-sm-4 col-form-label">サブスクリプション用</label>
                <div className="col-sm-8">
                  <input type="checkbox" {...register('for_subscription', {})} />
                </div>

                <small className="text-danger">{error}</small>
              </div>

              <input type="submit" className="btn btn-primary" value="作成" />
            </form>
          </div>
        ) : null}
      </div>
      <WindmillSpinnerOverlay loading={posting} message="クーポン作成中" />
    </div>
  );
}

export default IssueCoupon;
