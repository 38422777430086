import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { NavLink } from 'react-router-dom';
import { CouponNav } from './CouponNav';

function CouponUpload() {
  const [googleSheetUrl, setGoogleSheetUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setErrors([]);

    try {
      const auth = firebase.auth();
      const token = await auth.currentUser!.getIdToken();
      const apiEndPoint = `${process.env.REACT_APP_api_server}/coupons/from-sheet`;

      const response = await fetch(apiEndPoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sheet_url: googleSheetUrl }),
      });

      const data = await response.json();

      if (data.success) {
        setSuccess(true);
      } else {
        setErrors(data.errors || ['エラーが発生しました']);
      }
    } catch (err) {
      setErrors(['リクエスト処理中にエラーが発生しました']);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid h-100">
      <CouponNav />
      <h3>Google Sheetsからクーポン一括登録</h3>
      <div className="card mb-4">
        <div className="card-body">
          <h5 className="card-title">使い方</h5>
          <ol>
            <li>Google Sheetsにクーポン情報を入力します</li>
            <li>シートは google-directory-api@fdcd-tmc.iam.gserviceaccount.com と共有設定してください</li>
            <li>シートのURLを下のフォームに入力して送信します</li>
          </ol>
          <p>
            <strong>注意: </strong>
            エラーが1件でも含まれる場合、すべてのクーポンが登録されません。エラーが表示された場合は、内容を確認して修正してから再送信してください。
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="form-group row">
          <label htmlFor="googleSheetUrl" className="col-sm-2 col-form-label">
            Google SheetのURL
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              id="googleSheetUrl"
              value={googleSheetUrl}
              onChange={(e) => setGoogleSheetUrl(e.target.value)}
              placeholder="https://docs.google.com/spreadsheets/d/..."
              required
            />
          </div>
          <div className="col-sm-2">
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading ? '処理中...' : '登録'}
            </button>
          </div>
        </div>
      </form>

      {success && (
        <div className="alert alert-success mt-3">
          作成されたクーポンを <NavLink to="/admin/coupons">クーポン一覧</NavLink> で確認してください
        </div>
      )}

      {errors.length > 0 && (
        <div className="alert alert-danger mt-3">
          <ul>
            {errors.map((error) => (
              <li key={`error-${error.replace(/\s+/g, '-').substring(0, 20)}`}>{error}</li>
            ))}
          </ul>
        </div>
      )}

      <WindmillSpinnerOverlay loading={loading} message="処理中..." />
    </div>
  );
}

export default CouponUpload;
