import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import dayjs from './dayjs';
import firebase from './firebase';
import { CouponNav } from './CouponNav';

function Coupons() {
  const [coupons, setCoupons] = useState<any>();
  const [onlyEnabled, setOnlyEnabled] = useState<boolean>(true);
  const [showSubscription, setShowSubscription] = useState<boolean>(false);

  useEffect(() => {
    let query;

    if (showSubscription) {
      query = firebase.firestore().collection('coupons').where('for_subscription', '==', true);
    } else {
      query = firebase.firestore().collection('coupons').where('open', '==', true);
    }
    if (onlyEnabled) {
      query = query.where('enabled', '==', true);
    }
    const unregisterCouponsObserver = query.orderBy('start_period', 'desc').onSnapshot((snap) => {
      console.log('onSnapshot Coupons');
      const records = {};
      snap.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        records[docSnapshot.id] = data;
      });

      setCoupons(records);
    });

    return () => {
      unregisterCouponsObserver();
    };
  }, [onlyEnabled, showSubscription]);

  return (
    <div id="coupons" className="container-fluid h-80">
      <CouponNav />

      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="onlyEnabled"
          defaultChecked={onlyEnabled}
          onChange={(e) => setOnlyEnabled(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="onlyEnabled">
          現在有効なクーポンのみ表示
        </label>
      </div>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="showSubscription"
          defaultChecked={showSubscription}
          onChange={(e) => setShowSubscription(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="showSubscription">
          サブスクリプション用クーポン表示
        </label>
      </div>

      <div className="scrollable-coupons h-100">
        {coupons === undefined ? (
          <div className="row">
            <div className="col text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <>
            {Object.keys(coupons).length === 0 ? (
              <div className="row">
                <div className="col">
                  <div className="alert alert-light text-center" role="alert">
                    ありません
                  </div>
                </div>
              </div>
            ) : (
              <table className="table">
                <tbody>
                  <tr>
                    <th> </th>
                    <th>店舗専用クーポン</th>
                    <th>コード</th>
                    <th>集計グループ</th>
                    <th>開始日</th>
                    <th>終了日</th>
                    <th>使用された回数</th>
                    <th>一人あたり利用可能回数</th>
                    <th>割引率(%)</th>
                    <th>割引額</th>
                    <th>決済手段登録</th>
                  </tr>
                  {Object.keys(coupons).map((couponId) => (
                    <tr key={`coupon-${couponId}`}>
                      <td>
                        <NavLink to={`coupons/${couponId}`} className="nav-link" activClassName="active">
                          編集
                        </NavLink>
                      </td>
                      <td>{coupons[couponId].shop_ids && coupons[couponId].shop_ids.length > 0 ? '✅' : ''}</td>
                      <td>{coupons[couponId].code}</td>
                      <td>{coupons[couponId].data_group}</td>
                      <td>
                        {dayjs(coupons[couponId].start_period.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')}
                      </td>
                      <td>
                        {dayjs(coupons[couponId].end_period.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')}
                      </td>
                      <td style={{ textAlign: 'right' }}>{coupons[couponId].count || 0}</td>
                      <td style={{ textAlign: 'right' }}>{coupons[couponId].limit_per_customer}</td>
                      <td style={{ textAlign: 'right' }}>
                        {coupons[couponId].discount_percentage ? `${coupons[couponId].discount_percentage}%` : ''}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {coupons[couponId].discount_amount ? `\u00A5${coupons[couponId].discount_amount}` : ''}
                      </td>
                      <td>{coupons[couponId].require_stripe ? '必須' : '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Coupons;
